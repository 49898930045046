@import url('https://fonts.googleapis.com/css2?family=Ramabhadra&display=swap');

/* Side bar and Header  */


.container {
    display: flex;
    padding: 0;
    margin: 0;
    width: auto;
    height: 100%;
    max-height: 100vh;
}

/* Sidebar and Header */
.sidebar {
    position: relative;
    max-width: 350px;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background-color: #F3F3F3;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    transition: width 0.3s ease;
    padding-bottom: 25px;
    z-index: 1000; 
}

.sidebar.closed {
    width: 60px;
}

.sidebar .logo {
    display: flex;
    position: relative;
    align-items: center;
    padding: 20px;
    height: 100px;
}

.sidebar .line{
    margin-top: 0;
}
.dashboard-logo{
    max-width: 100%;
    margin-top: 20px;
    height: auto;
}

.sidebar .logo.closed {
    justify-content: center;
}

.line {
    margin-top: 50px;
    width: 100%;
}

.line hr {
    max-width: 90%;
    color: #CAC4D0;
    border: 1px solid;
}

.top-links {
    /* height: 70%; */
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.sidebar ul {
    list-style: none;
    padding-left: 10px;
}

.ul-paddings {
    padding: 0 20px 0 20px;
}

.ul-paddings-not {
    padding: 0 0px 0 0px;
}

.sidebar ul li {
    display: flex;
    align-items: center;
    padding: 15px 15px;
    cursor: pointer;
}

.sidebar ul li a {
    font-family: "Ramabhadra", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.sidebar ul li:hover {
    background-color: #f0f0f0;
}

.sidebar ul li.active {
    background-color: var(--background-dark-blue);
    transition: width 0.3s ease;
    border-radius: 8px;
    width: 80%;
    color: #fff;
}

.sidebar ul li.active img {
    filter: invert(100%);
}
.sidebar-mobile-icon{
    display: none;
}
.sidebar ul li img {
    width: 24px;
    height: 24px;
    margin-right: 20px;
    transition: filter 0.3s;
}

.sidebar ul li span {
    transition: opacity 0.3s;
}

.sidebar.closed ul li span {
    opacity: 0;
}
.toggle-btn {
    position: absolute;
    top: 60px;
    left: calc(100% - 60px);
    background-color: var(--background-dark-blue);
    border: none;
    color: #fff;
    padding: 5px;
    cursor: pointer;
    border-radius: 20%;
    z-index: 999;
}

.toggle-center {
    position: absolute;
    top: 45px;
    left: 18px;
    border: none;
    color: #fff;
    padding: 5px;
    cursor: pointer;
    border-radius: 20%;
    transform: rotate(180deg);
    transition: transform 0.3s ease; 
}
.logout-section-container{
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.logout-section .line{
    margin-top: 0;
}
.logout-section .logout{
    padding: 0 15px;
}

.collapsed ul li.active{
    width: 41%;          
}

body.sidebar-collapsed .header .cart {
    margin-right: 150px;
  }
.main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: 350px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    position: fixed;
    z-index: 1000; 
    width: 100%;
    margin-bottom: 20px;
    background: url('../../../public/images2/header-back.png') no-repeat center center/cover;
}

.form-area {
    padding-left: 40px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
}

.profile {
    display: flex;
    align-items: center;
}

.dash-header-logo{
    display: none;
}


.profile img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-right: 20px;
    border: 3px solid white;
}

.profile-info h2 {
    margin: 0;
    font-size: 18px;
    color: white;
}

.profile-info p {
    margin: 0;
    color: #C4C4C4;
}
.cart{
    margin-right: 410px;
}

.header-dots{
    display: none;
}


h1 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

.form-area-line {
    margin-top: -10px;
    margin-bottom: 40px;
    width: 100%;
}

.form-area-line hr {
    color: #D0CFCF;
    border: 1.5px solid #D0CFCF;
}

@media (max-width: 800px) {
    .container {
        flex-direction: column;
    }

    .sidebar {
        position: relative;
        max-width: 100%;
        height: auto;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        /* padding: 10px; */
    }
    .main-content{
        margin-left: 0;
    }
    .sidebar .logo {
        display: none;
    }

    .line {
        display: none;
    }

    .logout-section {
        display: none;
    }

    .top-links {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        position: fixed;
        background-color: #F9F9FF;
        bottom: 0px;
        left: 0;
        height: 100px;
        width: 100vw;
        padding: 0;
        /* gap: 10px; */
    }
    .user-guide-button{
        display: none;
    }
    .sidebar ul {
        margin: 0;
        padding-left: 0;
    }
    .sidebar ul li {
        flex: 1;
        justify-content: center;
        padding: 10px 5px;
        background: none;
    }
    .sidebar-desktop-icon{
        display: none;
    }
    .sidebar-mobile-icon{
        display: block;
    }
    .sidebar ul li.active {
        background: none;
        position: relative; /* Ensure the pseudo-element is positioned correctly */
        transition: width 0.3s ease;
        border-radius: 8px;
        color: #fff;
    }
    .sidebar ul li.active img {
        filter: invert(0);
    }    
    
    .sidebar ul li.active::after {
        content: '';
        position: absolute;
        left: 50%; /* Center the dot horizontally */
        bottom: -10px; /* Position the dot below the item */
        width: 8px; /* Adjust the width of the dot */
        height: 8px; /* Adjust the height of the dot */
        background-color: #0056b3; /* Blue color for the dot */
        border-radius: 50%; /* Make it a circle */
        transform: translateX(-50%); /* Center the dot */
    }
    

    .sidebar ul li span {
        display: none;
    }

    .header {
        position: fixed;
        width: 100vw;
        top: 0px;
    }

    .toggle-btn {
        display: none;
    }

    .toggle-center {
        top: 10px;
        left: 10px;
    }
    .sidebar ul li img {
        margin: 0;
        width: 65%;
        height: 65%;
    }
    .cart {
        display: none;
    }
    .header-dots{
        display: inline-block;
        margin-right: 50px;
    }    
    .profile{
        display: none;
    }
    .dash-header-logo{
        display: inline-block;
        filter: saturate(2);
    }
    
}


/* -------------------------- */


/* Content */

.content-section{
    margin-top: 100px;
    padding: 15px;
}

@media (max-width: 600px) {
    .content-section{
        margin-bottom: 100px;
    }
} 

/* Cataloge Section */

.cataloge-section{
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
}
.catalog-header {
    border-bottom: 1px solid #ddd;
    padding: 10px ;
}

.catalog-nav {
    display: flex;
    justify-content: space-between;
    /* width: 90%; */
    gap: 20px;
    margin: auto;
    overflow-x: scroll; /* Enables horizontal scrolling */
    white-space: nowrap; /* Prevents items from wrapping to the next line */
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.catalog-nav::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}

.catalog-link {
    text-decoration: none;
    margin: auto;
    color: #000;
    font-weight: bold;
}
.catalog-link.active {
    color: var(--background-dark-blue);
    border-bottom: 2px solid var(--background-dark-blue);
}

.cataloge-mobile-header{
    display: none;
}

.catalog-title {
    font-size: 24px;
    margin-bottom: 20px;
}

.catalog-items {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.catalog-item {
    border-radius: 8px;
    width: 405px;
    height: auto;
    padding: 10px;
    position: relative;
}

.catalog-image {
    width: 100%;
    border-radius: 8px;
    height: 240px;
}

.catalog-info {
    padding: 10px;
    text-align: left;
}

.catalog-item-title {
    font-size: 18px;
    margin: 10px 0;
}

.catalog-item-date {
    color: #888;
    font-size: 14px;
    margin-bottom: 10px;
}

.catalog-cart-icon {
    position: absolute;
    bottom: 30px;
    right: 10px;
    font-size: 20px;
    color: #000;
    cursor: pointer;
}

.catalog-cart-icon:hover {
    color: #007bff;
}

.center-button {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    background-color: var(--background-dark-blue);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    z-index: 2; 
}

.primary-center-button {
    display: none;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 40px;
    background-color: #EF7125;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    z-index: 2; 
}

.center-button:hover {
    background-color: #0056b3;
}

.primary-center-button:hover {
    background-color: #EF7125;
}

.catalog-item:hover .center-button,.catalog-item:hover .primary-center-button, .catalog-item:hover .buy-button {
    display: block;
    /* top: 50%;
    left: 40%;
    transform: translate(-105%, -10%); */
}

.softwarename{
    padding: 20px;
    border: 1px solid #ddd;
    font-size: 16px;
    width: 70%;
    border-radius: 10px;
    margin: 10px 0px;
}


@media (max-width: 600px) {
    .catalog-link {
        border: 1px solid #E4E7EC;
        padding: 10px;
        border-radius: 999px;
    }
    .catalog-link.active {
        color: #6941C6;
        border-bottom: 0px;
        background-color: #F4EBFF;
    }
    .catalog-items{
        justify-content: center;
    }
    .catalog-title{
        display: none;
    }
    .cataloge-mobile-header{
        display: block;
        text-align: center;
        background-color: #FF7522;
        padding: 10px 10px;
    }
    .cataloge-section-complete{
        padding: 0;
    }
    .softwarename{
       
        width: 100%;
       
    }
}




@media (max-width: 768px) {
    .catalog-item {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .catalog-info {
        align-self: flex-start;
    }
    /* .catalog-item-date{
        display: none;
    } */
    .catalog-cart-icon{
        display: none;
    }

    .catalog-item .center-button {
        display: block;
        top: 50%; /* Move to 50% from the top of the parent */
        left: 50%; /* Move to 50% from the left of the parent */
        transform: translate(-50%, -50%);
        width: max-content;
    }
    .catalog-item .primary-center-button {
        display: block;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: max-content;
    }
    .catalog-item .buy-button {
        display: block;
        width: max-content;
    }
}

@media (max-width: 375px) {
    .catalog-info {
        bottom: 5%;
    }
    .catalog-item-date{
        display: none;
    }
    .catalog-cart-icon{
        display: none;
    }
    .center-button {
        display: block;
        top: 30%;
        left: 30%;
        transform: translate(-65%, 40%);
    }
    .primary-center-button {
        display: block;
        top: 20%;
        left: 30%;
        transform: translate(-65%, 40%);
    }
    .adjust-postion{
        top: 38%;
        left: 44%;
    }
}

/* ------------------------------ */

/* Configuration Page */


.configuration-container {
    width: 70%;
    margin: auto;
    border-radius: 8px;
    padding-top: 30px;
}
.configuration-header {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
}
.configuration-nav {
    display: flex;
    justify-content: space-between;
    width: 90%;
    gap: 20px;
    margin: auto;
    overflow-x: scroll;
    white-space: nowrap;
    scrollbar-width: none; 
    -ms-overflow-style: none; 
}

.configuration-nav::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}

.configuration-link {
    margin: auto;
    text-decoration: none;
    color: #000;
    font-weight: bold;
}

.configuration-link:hover {
    text-decoration: underline;
}


.configuration-title {
    text-align: center;
    font-size: 24px;
    margin: 20px auto;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    width: 70%;
}

.markup-list {
    margin-bottom: 50px;
}

.markup-item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin: auto;
    margin-bottom: 10px;
    width: 100%;
}

.range-input,
.type-input,
.optional-input {
    padding: 20px;
    border: 1px solid #ddd;
    font-size: 16px;
}

.range-input {
    width: 93%;
}

.type-input {
    width: 100%;
    /* margin-right: 10px; */
}
.right-side-inputs{
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
}
.left-side-inputs{
    width: 100%;
}
.right-side-inputs::before {
    content: "⋮";
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    color: #000;
    font-size: 16px; 
    margin-right: 5px;
    width: 100%;
}

.add-button {
    padding: 0;
    border: 1px solid #7E869E40;
    border-radius: 46px;
    cursor: pointer;
    width: 25px;
    height: 25px;
    position: absolute;
    right: 20px;
    top: 8px;
    bottom: 8px;
    margin: auto;
  }
  
  .input-button-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
  }
  

.add-button:hover {
    background-color: #0056b3;
}

.optional-fields {
    display: flex;
    /* gap: 67px; */
    justify-content: space-between;
}

.input-container {
    width: 32%;
    display: flex;
    position: relative;
}

.input-container::before {
    content: attr(data-label); /* Use the data-label attribute for the text */
    position: absolute;
    top: -10px; /* Adjust as needed */
    left: 10px; /* Adjust as needed */
    background-color: white; /* Match background color to the page */
    font-weight: 700;
    line-height: 18px;
    font-size: 16px;
    color: #666; /* Adjust text color */
}

.optional-input {
    width: 100%;
    padding: 20px;
    border: 1px solid #ddd;
}

@media (max-width: 868px) {
    .configuration-container {
        width: 95%;
    }
    .markup-item {
        width: 100%;
        /* gap: 5px; */
    }
    .configuration-title {
        background-color: orange;
        font-size: 12px;
        padding: 10px 0;
    }    

    .range-input,
    .type-input {
        border-radius: 0px;
        width: 100%;
        text-align: left;
    }

    .right-side-inputs {
        width: 100%;
    }
    .range-input{
        background-color: #1A77D3;
        text-align: center;
    }
    .optional-fields {
        gap: 20px;
    }
    .input-container {
        width: 100%;
        /* margin: auto; */
        border-radius: 2px;
    }
        
    .optional-fields {
        flex-direction: column;
        align-items: flex-start;
        width: 96%;
        margin: auto;
    }

    .optional-field:last-child {
        margin-bottom: 0;
    }
}
/* ---------------------- */





/* subscription page */

.subscription-area {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.subscription-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.subscription-title {
    font-size: 24px;
    color: #333;
    margin: 20px 0px 0px 0px;
}

.subs-info {
    display: flex;
    flex-direction: column;
    margin-top: -30px;
}

.p-cart {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    text-align: left;
    color: #1E1E1E;
}

.p-cart-2 {
    font-size: 14px;
    font-weight: 500;
    line-height: 19.1px;
    text-align: left;
    color: #1E1E1E;
}

.cart-box {
    max-width: 580px;
    min-height: 114px;
    border-radius: 15px;
    box-shadow: 0px 1px 4px 0px #00000040;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 10px;
}

@media (max-width: 450px) {
    .cart-box {
        flex-direction: column;
    }
}


.info-cart {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.inp {
    display: flex;
    width: fit-content;
    align-items: center;
}


.info-cart p {
    font-family: 'Nunito', sans-serif;
    font-size: 13.5px;
    font-weight: 500;
    line-height: 18.41px;
    text-align: left;
    margin: 0;
    padding: 0;
}

.info-cart h4{
    margin-top: 0px;
}
.info-cart .inp p {
    font-family: 'Nunito', sans-serif;
    font-size: 10px;
    font-weight: 500;
    line-height: 16.37px;
    text-align: left;
    width: 100%;
}

.price-cart {
    display: flex;
    flex-direction: column;
    height: 80%;
    justify-content: space-between;
    /* padding-left: 20px; */
    align-items: center;
}

@media (max-width: 868px) {
    .price-cart {
        flex-direction: row;
        gap: 8px
    }

}

@media (min-width: 1025px) {
    .subscription-area {
        flex-direction: row;
        justify-content: space-between;
    }
}

@media (max-width: 868px) {

.info-cart {
    font-size: smaller;
}
.info-cart label{
    font-size: x-small;
}
.subscription-title {
    margin: 0;
    text-align: center;
}
}
/* --------------- */


/* Visa Card */

.visa-card {
    height: 699px;
    width: 388px;
    padding: 20px;
    background-color: #565ABB;
    border-radius: 20px;
    color: white;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;
}

.card-header h2 {
    margin: 0;
    font-size: 24px;
}

.profile-icon {
    width: 40px;
    height: 40px;
    background-color: #804fe5;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
}

.card-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.card-type img {
    width: 75px;
    height: auto;
}


.card-form {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.card-form label {
    font-size: 14px;
}

.card-form input {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #6268C6;
    color: #FEFCFC;
    font-size: 14px;
}

.card-form input::placeholder {
    color: #C4C4C4;
}

.card-details {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.card-details div {
    flex: 1;
}

.price-details {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.price-details div {
    display: flex;
    justify-content: space-between;
}

.checkout-button {
    background-color: #4DE1C1;
    color: #4b259b;
    padding: 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}


/* ------------------------------ */

/* Settings Section */

#settings {
    width: 90%;
    margin: 100px auto;
}
.settings-header{
    display: none;
}
.settings-all-data{
    width: 90%;
    /* padding: 0 40px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.settings-left-data{
    display: flex;
    flex-direction: column;
    width: 60%;
}
.tab-buttons {
    display: none;
    justify-content: space-between;
    margin-bottom: 20px;
}
.tab-content {
    display: none;
}

.tab-content.active {
    display: block;
}


.form-area{
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
}

.profile img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-right: 20px;
    border: 3px solid white;
}

.profile-info h2 {
    margin: 0;
    font-size: 18px;
    color: white;
}

.profile-info p {
    margin: 0;
    color: #C4C4C4;
}
.setting-container {
    background-color: #fff;
    border-radius: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.setting-container h1 {
    margin-top: 0;
    margin-bottom: 10px;
}
.main-form-group{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    width: 100%;
    gap: 10px;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    width: auto;

}
.form-group label {
    margin-bottom: 5px;
    font-weight: bold;
}
.form-group input {
    padding: 10px;
    border: 1px solid #E5E5ED;
    border-radius: 9px;
}
.email-group{
    display: flex;
    flex-direction: row;
    padding: 10px;
    border: 1px solid #E5E5ED;
    border-radius: 9px;
}
#email{
    outline: none;
    border: none;
    width: 100%;
    padding: 2px;
}
.connection-container {
    background-color: white;
    border-radius: 8px;    
    width: 100%;
}

.linkgroup{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
}

.input-group {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    /* width: 60%; */
    
}

.input-group:last-child {
    margin-bottom: 0;
}

.icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    background-size: contain;
    background-repeat: no-repeat;
}

.google-icon {
    background-image: url('../../../public/images2/google-logo.svg');
}

.facebook-icon {
    background-image: url('../../../public/images2/facebook.svg');
}

input {
    border: none;
    outline: none;
    /* flex-grow: 1; */
    font-size: 16px;
}

button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px 5px;
    font-size: 18px;
}

button.link-button {
    color: #4CAF50;
}

button.delete-button {
    color: #f44336;
}

.updates-container{
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}
.updates-container .updates{
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    gap: 20px;
}
.updates h2{
    margin: 0;
    padding: 0;
}

.profile-pic {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    border: 1px solid #E6E6E6;
    padding: 50px;
    border-radius: 6px;
}
.profile-pic .avatar {
    width: 174px;
    height: 174px;
    border-radius: 50%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    margin-bottom: 10px;
    position: relative;
  }
  
  .profile-pic .avatar img {
    width: 174px;
    height: 174px;
    border-radius: 50%;
  }
  
  .profile-pic .avatar .pic-add-button {
    position: absolute;
    bottom: 8px;
    right: 8px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
  }
  
.profile-pic button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 5px 0;
    width: 120%;
}
.profile-pic button.upload {
    background-color: var(--background-dark-blue);
    color: #fff;
    border-radius: 9px;
}
.profile-pic button.remove {
    background-color: #EF7125;
    color: #fff;
    border-radius: 9px;
}
.actions {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    gap: 40px;
    width: 100%;
}
.actions .account-actions {
    display: flex;
    gap: 100px;
    width: 100%;
}
.security-line{
    display: none;
}

.bottom-butons{
    gap: 40px;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    margin-top: 30px;

}
.bottom-butons button {
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}
.bottom-butons button.save {
    background-color: var(--background-dark-blue);
    color: #fff;
}
.bottom-butons button.cancel {
    border: 1px solid #6c757d;
    color: #FF7575;
}
.actions a {
    color: #dc3545;
    text-decoration: none;
}
.action-button{
    color: #dc3545;
    background-color: transparent;
    margin: 0px;
    padding: 0px;
    font-size: 16px;
    
}


@media (max-width: 868px) {
    .setting-container h1 , .form-area-line {
        display: none;
    }
    .connection-container h1{
        display: none;
    }
    .updates h2{
        display: none;
    }
    #security h1{
        display: none;
    }
    .settings-header{
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
    }
    .settings-title{
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
    }
    .tab-buttons {
        position: fixed;
        width: 80%;
        margin: auto;
        display: flex;
        overflow-x: auto; /* Enables horizontal scrolling */
        flex-direction: column;
        background-color: white;
        padding: 20px;
        z-index: 1001;
        -webkit-overflow-scrolling: touch; /* Smooth scrolling on touch devices */
    }
    
    .tab-buttons::-webkit-scrollbar {
        display: none; /* Hides the scrollbar on WebKit browsers */
    }
    
    .tab-button {
        flex: 1 0 auto; /* Ensures buttons don't shrink or grow to fit the container */
        white-space: nowrap; /* Prevents text from wrapping */
        padding: 15px;
        cursor: pointer;
        border-radius: 2px;
        border: 2px solid #D8D0D0;
        margin: 3px;
    }
    
    .tab-button.active {
        color: #1A77D3;
    }
    .settings-all-data {
        width: 90%;
        padding: 0px;
        margin: auto;
    }
    .settings-left-data{
        width: 100%;
    }
    .actions .account-actions {
        flex-direction: column;
        gap: 15px;
    }
    .security-line{
        display: block;
        margin: 0;
    }
    
    .main-form-group {
        display: flex;
        flex-direction: column;
    }
    .profile-pic {
        border: none;
    }
    .bottom-actions {
        display: flex;
    }
    .bottom-butons {
        margin: auto;
        gap: 17px;
        justify-content: center;
        flex-direction: column-reverse;
        margin-top: 30px;
        width: 50%;
    }
    #settin{
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        margin: 0; /* Remove default margins */
        z-index: 9999; /* Ensure it appears above other elements */
    }
}


@media (min-width: 869px) {
    .tab-content {
        display: block;
    }

    .bottom-actions {
        display: none;
    }
}


/* --------------------- */



/* User Guide Section */


.user-guide-section {
    width: 80%;
    margin: 20px auto;
    /* padding: 20px; */
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    gap: 24px;
}

.user-guide-title {
    font-size: 24px;
    font-weight: bold;
}

.user-guide-description {
    font-size: 16px;
}

.user-guide-section-container {
    display: flex;
    flex-direction: column;
    gap: 48px;

    @media (min-width: 600px) {
        flex-direction: row;
    }
}

.user-guide-video {
    width: 100%;
    height: 50vh;
    position: relative;
}

.user-guide-video iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: none;
}

.user-guide-help {
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.help-icon {
    font-size: 24px;
    margin-right: 15px;
}

.help-content {
    flex: 1;
}

.help-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
}

.help-description {
    font-size: 14px;
}

.help-description a {
    color: #007BFF;
    text-decoration: none;
}

.help-description a:hover {
    text-decoration: underline;
}


@media (max-width: 768px) {
    .user-guide-section {
        width: 100%;
    }
    .user-guide-video {
        height: 25vh;
    }
}


/* --------------------------------------- */









/* added by hamza */
.cancel-box{
    padding: 20px;


}


.buy-button {
    display: none;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    background-color: #EF7125;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    z-index: 2;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide number input arrows in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.tagline-banner{
    display: flex;
    gap: 15px;
    align-items: center;
    background-color: #EDEDED;
    width: 90%;
    min-height: 50px;
    margin-top: 20px;
    border-radius: 11px;
    padding: 20px;
}

.blog-auther-img{
    border-radius: 50%;
}
