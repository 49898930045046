@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
    --background-dark-blue: #00376e;
}

/* styles.css */
body {
    margin: 0;
    font-family: 'Inter';
    
}


body, html {
    overflow-y: auto; /* Allow vertical scrolling */
    overflow-x: hidden; /* Prevent horizontal scrolling (optional) */
    height: 100%;
    margin: 0;
}

body::-webkit-scrollbar, html::-webkit-scrollbar {
    width: 10px; /* Ensure the scrollbar is visible */
}
body::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners */
}

body::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Darker color on hover */
}

body::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Color of the scrollbar track */
}

.navbar {
    position: fixed;
    width: 100%;
    background-color: #F5F7FA;
    z-index: 10;
}

.nav-items {
    width: 90%;
    margin: auto; /* Added later */
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F5F7FA;
    padding: 11px 36px;
}

.navbar-logo img {
    height: 61px;
    width: 215px;
}

.navbar-links {
    display: flex;
    gap: 50px;
}

.navbar-links a {
    text-decoration: none;
    /*text-decoration-color: #EF7125; !* Custom color *!*/
    /*text-decoration-style: solid; !* Custom underline style: solid, dashed, dotted, etc. *!*/
    /*text-decoration-thickness: 3px;*/
    /*text-underline-offset: 4px;*/
    color: #18191F;
    font-family: inter;
    font-weight: 400;
}

.navbar-buttons {
    display: flex;
    gap: 10px;
    margin-right: 36px;
}

.navbar-buttons .btn {
    text-decoration: none;
    color: #2894FF;
    font-family: inter;
    padding: 10px 20px;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
}

.navbar-buttons .active-btn {
    text-decoration: none;
    color: #FFFFFF;
    font-family: inter;
    background-color: var(--background-dark-blue);
    padding: 10px 20px;
    line-height: 20px;
    font-weight: 500;
    font-size: 14px;
}

.navbar-buttons .active-btn:hover {
    background-color: #295ace;
}

.menu-icon {
    display: none;
    cursor: pointer;
    flex-direction: column;
    gap: 5px;
}

.menu-icon .bar {
    width: 25px;
    height: 3px;
    background-color: #000;
}

.mobile-menu {
    display: none;
    flex-direction: column;
    align-items: center;
    background-color: #F5F7FA;
    width: 100%;
    padding-top: 20px;
}

.mobile-links,
.mobile-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    width: 100%;
    text-align: center;
}

.mobile-links a {
    text-decoration: none;
    color: #18191F;
    font-family: inter;
    font-size: 16px;
    padding: 20px 0px 20px 0px;
    background-color: #e0e0e0;
    width: 100%;
    font-weight: 500;
}

.mobile-buttons{
    display: flex !important ;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
}

.mobile-buttons .btn {
    border-radius: 6px;
    text-decoration: none;

}
.mobile-buttons .active-btn {
    text-decoration: none;
    text-align: center;
    color: #FFFFFF;
    font-family: inter;
    background-color: #2894FF;
    padding: 10px 20px;
    font-weight: 500;
    font-size: 14px;
}

.mobile-buttons .active-btn:hover {
    background-color: #295ace;
}
@media (max-width: 1024px) {
    .navbar-links,
    .navbar-buttons {
        display: none;
    }

    .menu-icon {
        display: flex;
    }

    .mobile-menu.active {
        display: flex;
    }

    .navbar {
        padding: 20px 0px;
    }

    .nav-items {
        padding: 0;
    }

    .navbar-logo img {
        height: 50px;
        width: auto;
    }
}

/* iPhone 14 Pro Max */
@media (max-width: 430px) and (min-width: 375px) {
    .navbar {
        padding: 20px 0px;
    }
}

/* iPad Mini and iPad Air */
@media (max-width: 810px) and (min-width: 768px) {
    .navbar {
        padding: 20px 0px;
    }
}

/* iPad Pro (12.9-inch) */
@media (max-width: 1366px) and (min-width: 1024px) {
    .navbar {
        padding: 20px 0px;
    }
}

/* ----------------------------- */

/* Home */

.home {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--background-dark-blue); /* Light blue background */
    padding: 50px;
    height: 90vh; /* Full viewport height */
}

.home-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 87vw; /* Limit width for larger screens */
    gap: 4rem; /* Adds more spacing between the text and the video */
    padding: 1rem; /* Adds padding around the content */
}

.text-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    /* margin-right: 20px; */
}

.text-content h1 {
    margin: 0;
    color: #FFFFFF;
    /* width: 35vw; */
    font-weight: 600;
    font-size: 60px;
}

.text-content p {
    width: 40vw;
    color: #ABABAB;
    font-weight: 400;
    font-size: 15px;
    line-height: 40px;
    margin: 10px 0 20px;
}

.home-content-subtext {
    font-size: 20px !important;
}

.email-form {
    display: flex;
    width: 380px;
    height: 55px;
    gap: 0;
}

.email-form input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px 0 0 4px; 
    flex: 1;
    border-right: none;
}

.email-form button {
    padding: 18px 26px 18px 26px;
    border-left: none;
    border: 1px solid #ff7f00; /* Orange border */
    border-radius: 0px 8.32px 8.32px 0px; 
    background-color: #EF7125; 
    color: #fff;
    cursor: pointer;
    font-size: 1em;
}

.email-form button:hover {
    background-color: #cc6600;
}


.image-content {
    flex: 1;
    text-align: right;
    margin: 0 24px;
}

.image-content img {
    height: auto;
    width: 100%;
}


/* Media queries for responsiveness */
@media (max-width: 1124px) {
    /* .home{
        padding-top: 25vh;
        padding-bottom: 20vh;
    } */
    .home-content {
        margin-top: 45px;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .text-content {
        width: 100%;
        margin-bottom: 10px;
    }

    .text-content h1 {
        font-size: 6vh;
        text-align: left;
        width: 100%;
    }

    .text-content p {
        text-align: left;
        width: 90%;
    }

    .email-form {
        width: 100%;
        justify-content: center;
    }

    .email-form input {
        width: 70%;
        border-radius: 4px;
    }

    .email-form button {
        width: 30%;
        border-radius: 4px;
    }
    .image-content img {
        height: 40vh;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .home {
        padding-top: 50px;
        height: auto;
    }

    .home-content {
        gap: 40px;
        width: 90vw;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .text-content {
        gap: 5px;
        width: 90vw;
        margin-bottom: 20px;
    }

    .text-content h1 {
        margin: 0;
        color: #FFFFFF;
        /* width: 315px; */
        font-weight: 400;
        font-size: 23px;
        line-height: 45px;
        text-align: left;
    }

    .text-content p {
        width: 100%;
        text-align: left;
    }

    .email-form {
        margin-top: 50px;
        margin-bottom: 50px;
        flex-direction: column;
        width: 100%;
    }

    .email-form input {
        padding: 18px 0 18px 20px;
        width: 94%;
        border-radius: 4px;
        margin-bottom: 10px;
    }

    .email-form button {
        width: 100%;
        border-radius: 4px;
    }

    .image-content {
        width: 100%;
        text-align: center;
    }

    .image-content img {
        width: 100%;
        height: 100%;
    }
}
/* --------------------------- */

/* Compatibility  */

.compatibility-section {
    text-align: center;
    margin-bottom: 50px;
}

.section-heading {
    color: #4D4D4D;
    font-size: 36px;
    font-weight: 600;
    line-height: 45px;
}

.compatibility-logos {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: auto;
    max-width: 70vw;
    gap: 35px 110px;
}

.compatibility-logos .logo {
    max-height: 79px;
    max-width: 211px;
}
/* ---------------------------- */

/* Fatures */

.features-section {
    margin-top: 100px;
    text-align: center;
    margin-bottom: 50px;
}

.features-heading {
    color: #303030;
    font-size: 36px;
    font-weight: 600;
    line-height: 45px;
}

.section-subtitle {
    font-size: 23px;
    font-weight: 600;
    line-height: 44px;
    color: #423F3F;
    max-width: 90vw;
    margin: 0 auto 40px auto;
}

.features-content {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 200px;
}

.features-image {
    width: 625px;
    height: 385px;
}

.features-details {
    width: 359px;
    /* height: 289px; */
    text-align: left;
}

.features-subheading {
    color: #545353;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
}

.features-list {
    list-style: none;
    /* padding: 0; */
    /* width: 359px;
    margin-left: 15px; */
}

.features-list li {
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;
}

.features-list img {
    margin: 5px;
    margin-right: 10px;
}

/* For screens up to 1024px (iPad Pro, iPad Air) */
@media (max-width: 1050px) {
    .compatibility-logos {
        gap: 50px;
    }
    .compatibility-logos .logo {
        max-height: 79px;
        max-width: 200px;
    }
    .features-content {
        flex-direction: column;
        gap: 50px;
    }

    .features-image {
        width: 80%;
        height: auto;
    }

    .features-details {
        width: 80%;
    }
}

/* For screens up to 834px (iPad Mini) */
@media (max-width: 834px) {
    .compatibility-logos {
        gap: 30px;
    }
    .compatibility-logos .logo {
        max-height: 79px;
        max-width: 150px;
    }

    .section-subtitle {
        font-size: 18px;
        line-height: 34px;
    }

    .features-content {
        flex-direction: column;
        gap: 30px;
    }

    .features-image {
        width: 90%;
        height: auto;
    }

    .features-details {
        width: 90%;
    }
}

/* For screens up to 43-px (iPhone 14 Pro Max) */
@media (max-width: 430px) {
    .features-section {
        margin-bottom: 10px;
    }
    .features-heading {
        font-size: 15px;
        line-height: 44px;
    }
    .section-heading {
        font-size: 15px;
        line-height: 44px;
    }

    .compatibility-logos {
        max-width: 90vw;
    }

    .compatibility-logos .logo {
        max-height: 79px;
        max-width: 100px;
    }
    .features-heading {
        font-size: 28px;
        line-height: 35px;
    }

    .section-subtitle {
        font-size: 16px;
        line-height: 44px;
        margin: 0 auto 30px auto;
    }

    .features-content {
        flex-direction: column;
        gap: 20px;
    }

    .features-image {
        width: 90vw;
        height: auto;
    }

    .features-details {
        width: 90%;
    }

    .features-subheading {
        font-size: 18px;
    }

    .features-list li {
        font-size: 14px;
    }
}
/* ------------------------------------ */

/* Industries */

.industries-section {
    max-width: min-content;
    margin: auto;
    text-align: center;
    margin-bottom: 50px;
    justify-items: center;
}

.industries-heading {
    color: #303030;
    font-size: 36px;
    font-weight: 600;
    line-height: 45px;
    margin-bottom: 50px;
}

.industries-content {
    display: grid;
    grid-auto-flow: column;
    gap: 20px;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    margin: 0 24px;
    max-width: 100%;

    :first-child div {
        margin-left: 24px;
    }
}

.industries-content::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}
.industry-image {
    width: 416px;
    height: fit-content;
    min-height: 416px;
    border-radius: 11px;
    margin-bottom: 10px;
}

.industry-item {
    width: 416px;
}

.industry-text {
    font-size: 34px;
    font-weight: 700;
    color: #333;
}
/* ---------------------- */

/* Video */

.video-section {
    margin-top: 100px;
    padding-top: 10px;
    text-align: center;
    background-image: url('../../../public/images/video-section.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    padding-bottom: 100px;
}

.video-heading {
    color: #303030;
    font-size: 36px;
    font-weight: 600;
    line-height: 45px;
    margin-bottom: 50px;
}

.brand-name {
    color: #FF5733;
}
.brand-name2 {
    color: rgb(6, 6, 90);
}

.video-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-placeholder {
    width: 85vw;
    height: 550px;
    margin: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    overflow: hidden; 
}

.video-placeholder iframe {
    width: 100%;
    height: 100%;
    border: none; 
}


.pause-icon {
    width: 50px;
    height: 50px;
}

/* Media Queries */

/* For screens up to 1024px (iPad Pro, iPad Air) */
@media (max-width: 1024px) {
    .industries-content {
        gap: 15px;
    }

    .industries-content {
        justify-content: flex-start;
    }

    .video-placeholder {
        width: 90vw;
    }
}

/* For screens up to 834px (iPad Mini) */
@media (max-width: 834px) {
    .industries-content {
        justify-content: flex-start;
    }

    .video-placeholder {
        width: 90%;
    }
}

/* For screens up to 428px (iPhone 14 Pro Max) */
@media (max-width: 430px) {
    .industries-heading,
    .video-heading {
        font-size: 18px;
    }
    .industries-section {
        margin-top: 100px;
        margin-bottom: 10px;
    }
    .industry-image {
        width: 80vw;
        height: 90vw;
    }

    .industry-item {
        width: 80vw;
    }
    .video-section {
        margin-top: 0px;
        padding-bottom: 50px;
    }

    .industries-content {
        justify-content: flex-start; 
    }

    .video-placeholder {
        width: 95%;
        height: 200px;
    }
}
/* --------------------------------- */

/* Benefits */

.benefits-section {
    text-align: center;
    padding: 50px 20px;
    background: linear-gradient(180deg, #FFF7EF,   #FFFFFF);
}

.benefits-title {
    color: #303030;
    font-size: 43px;
    font-weight: 600;
    line-height: 45px;
    margin-bottom: 10px;
}

.benefits-subtitle {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    color: #000000;
    margin-bottom: 50px;
}

.benefits-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    gap: 20px;
    width: 90vw;
}

.benefit-item {
    border-radius: 10px;
    padding: 20px;
    max-width: 350px;
    text-align: left;
}

.benefit-icon {
    display: block;
    margin: 0 auto 20px;
    /* width: 250px; */
    height: 192px;
}

.benefit-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #000000;
}

.benefit-description {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #000000;
}
@media (max-width: 398px) {
.benefit-item {
    width: 100%;
}
.benefit-icon {
    width: 100%;
}
}

/* --------------------------- */

/* Steps */

.steps-section {
    max-width: 100%;
    /* margin: 0 auto; */
    padding: 20px;
    text-align: center;
    background: linear-gradient(to bottom, #FFFFFF, #FFF7EF, #FFFFFF);

}

.accuprice-title {
    font-size: 28px;
    font-weight: 600;
    line-height: 42px;
    /* margin-bottom: 10px; */
}

.accuprice-highlight {
    color: #ff6600; /* AccuPrice highlight color */
}

.accuprice-subtitle {
    font-size: 35px;
    font-weight: 600;
    line-height: 42px;
    /* margin-bottom: 30px; */
}

.accuprice-steps {
    position: relative;
    padding: 20px 0;
}

.step {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    width: 100%;
}

.step-left {
    justify-content: flex-end;
}

.step-content {
    padding: 20px;
    width: 45%;
    position: relative;
    z-index: 1;
}

.step-left .step-content {
    text-align: left;
    order: 1;
}

.step-right .step-content {
    text-align: right;
    order: 2;
}

.step::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: var(--background-dark-blue); /* Line color */
    z-index: 0;
}

.step-left::after,
.step-right::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 20px;
    width: 10px;
    height: 10px;
    background-color: #EF7125;
    border: 4px solid white;
    border-radius: 50%;
    transform: translateX(-50%);
    z-index: 1;
}

.step-number {
    font-size: 40px;
    font-weight: 700;
    color: #000000;
    line-height: 48px;
}

.step-title {
    font-size: 32px;
    font-weight: 700;
    color: #000000;
    line-height: 41px;
}

.step-description {
    font-size: 17px;
    font-weight: 400;
    line-height: 25px;
    color: 000000;
}



/* Media Queries for smaller screens */
@media (max-width: 768px) {
    .accuprice-title {
        font-size: 17px;
        line-height: 25px;
    }
    .accuprice-subtitle {
        font-size: 11px;
        line-height: 13px;
    }
    .step-number {
        font-size: 32px;
        line-height: 38px;
    }
    
    .step-title {
        font-size: 24px;
        line-height: 33px;
    }
    
    .step-description {
        font-size: 16px;
        line-height: 24px;
    }
    
    .step {
        justify-content: flex-start;
    }
    .step-left, .step-right {
        justify-content: flex-start;
    }
    .step-content {
        width: 80%;
        text-align: left;
        margin-left: 30px;
    }
    .step-left .step-content, .step-right .step-content {
        text-align: left;
    }
    .step::before {
        left: 4px;
        transform: none;
    }
    .step-left::after, .step-right::after {
        left: -4px;
        transform: none;
    }
}
/* -------------------------------------- */
/* Customer Feedback */

.customer-feedback-container {
    display: grid;
    text-align: center;
    margin: 0 auto;
    padding: 20px;
}

.customer-feedback-title {
    font-size: 39px;
    font-weight: 700;
    line-height: 54px;
    color: #133240;
    margin-left: 20px;
}

.customer-feedback-subtitle {
    font-size: 28px;
    line-height: 38px;
    font-weight: 400;
    color: #133240;
    margin-left: 20px;
}

.customer-feedback-slider {
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    padding-bottom: 20px;
}

.feedback-card {
    text-align: left;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 491px;
    height: 369px;
    margin: 0 10px;
    scroll-snap-align: start;
    flex: 0 0 auto;
    position: relative;
    border: 1px solid #e0e0e0; 
}


.feedback-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.feedback-avatar img {
    border-radius: 50%;
    width: 80px;
    height: 80px;
}

.feedback-stars {
    color: #F8A401;
    font-size: 44px;
    margin-top: -8px; 
}

.feedback-name {
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    color: #133240;
    margin: 0 0 4px;
}

.feedback-title {
    font-size: 17px;
    font-weight: 600;
    line-height: 23px;
    color: #133240;
    margin: 12px 0 12px;
}

.feedback-text {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    color: #333;
}

.feedback-pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px; 
}
.dot {
    height: 12px;
    width: 12px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}

.dot.active {
    background-color: #ff6600; /* Active dot color */
}

/* Hide scrollbar */
.customer-feedback-slider::-webkit-scrollbar {
    display: none;
}

.customer-feedback-slider {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Media Queries for smaller screens */
@media (max-width: 768px) {
    .customer-feedback-title {
        font-size: 22px;
        font-weight: 700;
        line-height: 30px;
    }
    .customer-feedback-subtitle {
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
    }
    .feedback-card {
        width: 78vw;
        height: 280px;
    }
    .feedback-avatar img {
        width: 60px;
        height: 60px;
    }
    .feedback-name {
        font-size: 24px;
    }
    .feedback-title {
        font-size: 15px;
    }
    .feedback-text {
        font-size: 16px;
    }
 
}
/* ---------------------- */


/* FAQS */

.faq-container {
    max-width: 95vw;
    margin: 50px auto;
    text-align: center;
}

.faq-heading {
    font-family: 'Roboto';
    text-align: center;
    font-size: 48px;
    font-weight: 700;
    line-height: 57px;
}
.faq-subheading{
    text-align: center;
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 100px;
}

.faq-item {
    border-bottom: 1px solid black;
    padding: 10px 0;
    text-align: left;
}

.faq-item:first-of-type {
    border-top: 1px solid black;
}

.faq-question {
    width: 100%;
    background: none;
    border: none;
    text-align: left;
    font-size: 21px;
    font-weight: 700;
    line-height: 31px;
    font-family: 'Roboto';
    padding: 10px;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq-question::after {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-right: 2px solid black;
    border-top: 2px solid black;
    transform: rotate(135deg);
    margin-left: 5px;
    transition: transform 0.3s ease;
}

.faq-question.active::after {
    transform: rotate(-45deg);
}

.faq-answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    font-size: 19px;
    font-weight: 400;
    line-height: 28px;
    padding: 0 10px;
}

@media (max-width: 768px) {
    .faq-question {
        font-size: 16px;
        line-height: 24px;
    }
    .faq-answer {
        font-size: 16px;
        line-height: 24px;
    }
}
/* ---------------------------- */


/* Signup here */
.signup-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--background-dark-blue);
    color: white;
    text-align: center;
    height: 489px;
    align-items: center;
}

.signup-footer h1 {
    font-size: 48px;
    font-weight: 700;
    line-height: 57px;
    margin: 20px;
    color: white;
}

.signup-footer p {
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
}

.signup-form {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.signup-form input {
    padding: 15px;
    font-size: 1em;
    border: none;
    width: 300px;
}

.signup-form button {
    padding: 15px 20px;
    font-size: 1em;
    color: white;
    background-color: #FF6600;
    border: none;
    cursor: pointer;
}

.terms-conditions {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-top: 20px;
}

.terms-conditions a {
    color: #000000;
    text-decoration: none;
}
/* ------------- */


/* Footer */

.footer-section {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #002244;
    color: white;
    height: 466px;
    text-align: center;
    padding: 40px 20px;
}

.footer-top {
    margin: auto;
    width: 85vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.footer-logo-social {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.footer-logo img {
    width: 178px;
    height: 53px;
}

.footer-social a {
    width: 18px;
    height: 18px;
    color: white;
    margin: 0 10px;
}


.footer-social a i{
    font-size: 20px;
}

.footer-newsletter {
    text-align: left;
    margin-right: 100px;
}

.footer-newsletter h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    margin-bottom: 10px;
}

.stay-uptodate{
    width: 335px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}


.newsletter-form {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}


.newsletter-form input {
    padding: 15px;
    font-size: 1em;
    border: none;
    width: 300px;
    /* max-width: 80%; */
}

.newsletter-form button {
    padding: 10px 20px;
    font-size: 1em;
    color: white;
    border: 1px solid orange;
    background-color: #002244;
    cursor: pointer;
}
 
.footer-bottom {
    width: 85vw;
    margin: auto;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #ffffff;
    padding-top: 20px;
}

.footer-links {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.footer-links a {
    color: white;
    font-size: 0.9em;
}

.footer-links a:hover {
    text-decoration: underline;
}

.footer-copyright {
    font-size: 14px;
    margin-top: 10px;
}

/* Media Queries */
@media (max-width: 768px) {    
    .signup-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .signup-footer h1 {
        font-size: 32px;
        font-weight: 700;
        line-height: 38px;
        margin: 20px;
    }
    .signup-footer p {
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        width: 85vw;
    }
    .signup-form {
        flex-direction: column;
        align-items: center;
    }
    .newsletter-form {
        flex-direction: column;
        align-items: center;
    }
    .footer-section {
        height: fit-content;
    }
    .footer-top {
        flex-direction: column;
        align-items: center;
    }
    .footer-social {
        margin-bottom: 30px;
    }
    .footer-bottom {
        flex-direction: column;
        gap: 20px;
    }
    .footer-links {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
    .footer-newsletter {
        margin: auto;
        width: 100%;
    }  
    .newsletter-form input {
        width: 80vw;
    }
    .newsletter-form button {
        width: 100%;
    }
    .signup-form button {
        width: 100%;
    }
}

@media (max-width: 360px) {
    .stay-uptodate {
        width: 100%;
    }
    .signup-form input {
        width: 100%;
    }
}


/* For screens up to 1024px (iPad Pro, iPad Air) */
@media (max-width: 1024px) {
    .signup-footer p {
        width: 85vw;
        margin: auto;
        margin-top: 15px;
        margin-bottom: 15px;
    }
}

/* ----------------------------------------------------------------------------------- */


/* All Blogs  Page */


/* Hero Section */

.hero-section{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 22vh;
    align-items: center;
    width: 100%;
}

.hero-section .logo{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.hero-section .logo img{
    padding-top: 20px;
    width: 40%;
}
.hero-section .tag-line{
    width: 100%;
    display: flex;
    justify-content: center;
}
.hero-section .tag-line p{
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: center;
}

/* Blog List Section */
.blog-list {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 200px;
}

.blog-list .options {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    padding-bottom: 50px;
    margin-right: 80px;
}

.blog-list .options a {
    text-decoration: none;
    color: #18191F;    
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    padding: 10px 16px 8px 16px;
}

.option-active {
    border-radius: 4px 0px 0px 0px;
    align-self: center;
    border: 1px solid #E8E9E8;
    border-color: #E8E9E8;
    opacity: 0px;
    text-align: center;
}

.blog-posts-post{
    text-decoration: none;
    color: #000;
}
.blog-posts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
    align-items: start;
    width: 95%;
    justify-content: space-between;
}


.post {
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 8px;
    overflow: hidden; 
    width: 100%;
}

.post .post-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    width: 100%;
}

.post-data-data{
    padding: 15px 5px;
}
.post .post-data .category {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
}

.post img {
    width: 100%;
    height: 300px;
}

.post .post-data .title {
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    line-height: 33.6px;
    text-align: left;
}

.post .post-data .para p {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: justify;
    width: 100%;
}

.post .post-data .author {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.post .post-data .author img {
    /* width: 20%; */
    width: 48px;
    height: 48px;
    
}



.post .post-data .author .post-date h6 {
    font-size: 16px;
    margin: 0;
}

.post .post-data .author .post-date p {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    margin: 7px 0px 0px 0px;
}

.post .post-data .author .post-date p span img {
    width: 100%;
    position: relative;
    top: -3px;
}

/* Responsive Styles */

@media (max-width: 1024px) {
    .blog-posts {
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    }
    
}

@media (max-width: 768px) {
    .hero-section .logo img{
        padding-top: 10px;
        width: 80%;
    }
    .blog-list .options {
        flex-direction: column;
        gap: 10px;
        margin-right: 0;
        padding-bottom: 10px;
    }
    
    .blog-posts {
        grid-template-columns: 1fr;
        gap: 20px;
        width: 100%;
    }

    .post {
        padding: 15px;
    }
    .post-data{
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    }
    .post .post-data .para p {
        width: 100%;
    }

    .post .post-data {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .post .post-data .author img {
        width: 15%;
    }
    
    .post .post-data {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
    }
    
    .post-date{
        display:flex;
        flex-direction: column;
    }
}
@media (max-width: 500px) {
    .post img{
        height: 240px;
    }
}

/* ------------------------------ */

/* Single Post */



/* main container */
.blog-post-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 2;
    align-items: center;
    max-width: auto;
    width: 100%;
    height: 100%;
}

.single-post {
    width: 100%;
    /* background-color: #2894FF; */
    display: flex;
    /* padding-left: 30px; */
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0px;
    padding-bottom: 100px;
}

.single-post .back-btn {
    width: 92%;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 20px;
}

.single-post .back-btn img {
    margin-top: -2px;
    cursor: pointer;
}

.single-post .back-btn p {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.single-post .readinfo-group {
    width: 92%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
    padding-bottom: 20PX;
}

.single-post .readinfo-group .category-time {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 20px;
    align-items: center;
}
.single-post .readinfo-group .single-post-title {
    font-family: 'Roboto', sans-serif;
    font-size: 48px;
    font-weight: 700;
    line-height: 57.6px;
    text-align: left;
}

.single-post .readinfo-group .category-time .category {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: center;
    padding-top: 4px;
    padding-right: 8px;
    padding-bottom: 4px;
    padding-left: 8px;
    background-color: #e2e1e1;
}

.single-post .readinfo-group .category-time .time {
    /* //styleName: Text/Small/Semi Bold; */
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
}

.single-post .single-post-data {
    width: 92%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.single-post .single-post-data img {
    width: 100%;
    max-height: 600px;
}

.single-post .single-post-data p {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 500;
    word-spacing: 2px;
    line-height: 30px;
    text-align: left;
}

.single-post .single-post-data .bottom-info {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 30px;
}

.single-post .single-post-data .bottom-info .social-links {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.single-post .single-post-data .bottom-info .social-links a {
    /* width: 20%;   */
    width: 32px;
    height: 32px;
    padding: 8px 8px 12px 8px;
    gap: 0px;
    border-radius: 64px;
    opacity: 0px;
    background-color: #e2e1e1;
}

.single-post .single-post-data .bottom-info{
    display: flex;
    flex-direction: row;
    gap: 40px;
}

.single-post .single-post-data .bottom-info  {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.single-post .single-post-data .bottom-info  {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.single-post .single-post-data .bottom-info  .written-by p {
    /* //styleName: Text/Regular/Normal; */
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;

}
.single-post .single-post-data .bottom-info .published-date p {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}
.single-post .single-post-data .bottom-info  .written-by h6 {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;

}
.single-post .single-post-data .bottom-info  .published-date h6 {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;

}

.author-info{
    display: flex;
    gap: 40px;
    align-items: center;
}

/* Responsive */

@media (max-width:800px) {

    .single-post {
        padding-top: 0px;
    }
    .social-links a{
        width: 20px;
        height: 20px;
    }
}


@media (max-width:600px) {
    .single-post .single-post-data p {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
    }
    .author-info{
        width: 20vw;
    }
    .single-post .single-post-data .bottom-info {
        flex-direction: column;
        gap: 40px;   
    }
    .author-info{
        display: flex;
        gap: 40px;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
    .single-post .single-post-data .bottom-info {
        width: 100%;
        justify-content: space-between;
    }
}

.flex {
    display: flex;
}





/* added by hamza */
.social-icon{
    width: 100% !important;
    height: 100%;
}
.social-link{
    width: 32px !important;
    height: 32px !important;
    padding: 10px !important;
}

/* price section */
.price-section{
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 112px 64px 112px 64px;
    background: linear-gradient(to bottom, #83A6FF 0%, #FFF7EF 70%);

}
.price-title{
    font-family: Roboto;
font-size: 55px;
font-weight: 700;
line-height: 66px;
text-align: center;
color: #FFFFFF;
margin: 0px;
}

.price-subtitle{
    font-family: Roboto;
font-size: 22px;
font-weight: 700;
line-height: 33px;
text-align: center;
color: #FFFFFF;
}
.price-section-wraper{
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
}

.price-cards-section{
    text-align: left;
margin-top: 80px;
display: flex;
gap: 30px;
width: 100%;
max-width: 1024px;
/* flex-wrap: wrap; */

}


.price-card{
   min-height: 665px;
   width: 50%;
   max-width: 665px;

padding: 32px;
border-radius: 3px;
display: flex;
flex-direction: column;
justify-content: space-between;

}
.price-card1{
    background: linear-gradient(180deg, #6C6FFF 0%, #F9E3E3 71.5%);
}
.price-card2{
    background: linear-gradient(180deg, #F28920 0%, #F9E3E3 71.5%);

}

.price-dollar-icon{
    display: flex;
    justify-content: end;
    margin-bottom: 16px;
}

.price-amount-section{
    color: #FFFFFF;
}
.price-amount-title{
font-family: Roboto;
font-size: 20px;
font-weight: 700;
line-height: 28px;
text-align: left;
margin: 0px;
}

.price-amount{
font-family: Roboto;
font-size: 56px;
font-weight: 700;
line-height: 67.2px;
text-align: left;
margin: 16px 0px;
}

.price-amount-cut{
font-family: Roboto;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;
text-decoration-line: line-through;
margin: 0px;
}

.price-hr{
    margin:32px 0px;
    border: 1px solid  #000000;
}
.price-includes-section{
    color:  #000000;

}
.price-includes-title{
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin-bottom: 16px;

}
.inlcude-items-section{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.inlucde-list-item{
    display: flex;
    gap: 16px;

}

.inlcude-text{
font-family: Roboto;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;
margin: 0px;

}

.price-button-move{
font-family: Roboto;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: center;
width: 100%;
height: 66px;
padding: 12px 24px 12px 24px;
gap: 8px;
border-radius: 29px;
background: #46337D;
color: #FFFFFF;



}


@media (max-width:800px) {
.price-cards-section{
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
    
}

@media (max-width:650px) {
    .price-card{
        width: 90%;
    }
        
    }
.video-content {
  position: relative;
  width: 100%;
  max-width: 800px; /* Adjust as needed */
  margin: 0 auto;
}

@media (min-width:769px) and (max-width:1125px) {
    .video-content {
        width: 75%;
    }
}

.video-content iframe {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9; /* Maintain 16:9 aspect ratio */
  border: none;
}
/* Updated Dropdown CSS */
.navbar {
    position: fixed;
    width: 100%;
    background-color: #F5F7FA;
    z-index: 10;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.nav-items {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.navbar-links {
    display: flex;
    gap: 30px;
    align-items: center;
}

.navbar-links a, .dropdown-btn {
    text-decoration: none;
    color: #18191F;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 16px;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.navbar-links a:hover, .dropdown-btn:hover {
    background-color: #2894FF;
    color: white;
}

.dropdown {
    position: relative;
}

.dropdown-btn {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.dropdown-content {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #FFFFFF;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-width: 200px;
    border-radius: 5px;
    overflow: hidden;
    z-index: 1;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown-content a {
    padding: 10px 15px;
    color: #18191F;
    display: block;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.dropdown-content a:hover {
    background-color: #f1f1f1;
}

.navbar-buttons .btn {
    color: #2894FF;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    padding: 10px 20px;
    border-radius: 6px;
    border: none;
    transition: background-color 0.3s ease;
}

.navbar-buttons .btn:hover {
    background-color: #2894FF;
    color: white;
}

.navbar-buttons .active-btn {
    background-color: #00376e;
    color: white;
}

.navbar-buttons .active-btn:hover {
    background-color: #295ace;
}

/* Mobile Menu */
@media (max-width: 1024px) {
    .navbar-links,
    .navbar-buttons {
        display: none;
    }

    .menu-icon {
        display: flex;
        cursor: pointer;
        flex-direction: column;
        gap: 5px;
    }

    .mobile-menu {
        display: none;
        flex-direction: column;
        align-items: center;
        background-color: #F5F7FA;
        width: 100%;
        padding-top: 20px;
    }

    .mobile-menu.active {
        display: flex;
    }

    .mobile-links a {
        text-decoration: none;
        color: #18191F;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        padding: 10px 0;
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #ddd;
    }

    .mobile-links a:hover {
        background-color: #e0e0e0;
    }
}
.image-section {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 20px 0;
}

.image-container {
    position: relative;
}

.clickable-image {
    width: 300px;
    height: auto;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.clickable-image:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.industry-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.industry-image:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Ensure text links inside cards don't inherit link styles */
.industry-card-link {
    text-decoration: none;
    color: inherit;
}

/* Remove underline from links in the text */
a {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

html {
  scroll-behavior: smooth;
}