@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: url(../../../public/images2/Backdrop.png);
    background-repeat: no-repeat;
    background-size: cover;

}

.signup-main-modal {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 24px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 5px 40px 20px 40px;
    max-width: 400px;
    margin: 20px;
    /* height: 60%; */
    max-height: 95vh;
    text-align: center;
    position: relative;
    /* gap:10px; */
}

.signup-modal {
    /* padding: 40px; */
    /* width: 50%; */
    height: 100%;
    /* padding: 10px; */
    /* background-color: #007bff; */

}

.modal-header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
    /* margin-top: -40px;
    margin-left: 40px; */
}

.modal-header h2 {
    margin: 0;
    font-size: 24px;
    color: #333;
}

.modal-header h2 span {
    color: orange;
}

.close-btn {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    /* padding: 0; */
    right: -30px;
    position: relative;
}
.close-btn img {
    width: 80%;
}


.signup-modal h3 {
    margin: 0px 0px 5px 0px;
    font-size: 32px;
    font-weight: 500;
    color: #333;
}

.register-form {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    margin-top: 20px;
}

.register-form input {
    margin: 5px 0;
    padding: 10px;
    border: 1px solid black;
    width: 85%;
    /* border-radius: 5px; */
}

.register-form button {
    width: 100%;
    height: 40px;
    margin: 10px 0;
    padding: 20px, 44px, 40px, 24px;
    border: none;
    border-radius: 27px;
    background-color: var(--background-dark-blue);
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.register-form button:hover {
    background-color: #0056b3;
}

.social-signup {
    display: flex;
    flex-direction: column;
     align-items: center; /* did this for login with google button  */
    /* margin: 10px 0; */
}

.social-signup button {
    margin: 10px 0;
    padding: 7px;
    height: 50px;
    border: 1px solid;
    border-radius: 27px;
    cursor: pointer;
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    gap: 20px;

    border-color: #333333;
}



.google-btn {
    color: #333333;
    text-align: center;
}

.terms {
    
    font-size: 12px;
    color: #777;
    /* margin: 0; */
    /* background-color: #0056b3; */
}

.terms a {
    color: #777;
    font-weight: bold;

    /* text-decoration: none; */
}

.terms a:hover {
    text-decoration: underline;
}

.login-link {
    margin: 0;
    font-size: 14px;
    color: #777;
    /* margin-bottom: -10px; */

}

.login-link a {
    color: #777;
    font-weight: bold;
    /* text-decoration: none; */
}

.login-link a:hover {
    text-decoration: underline;
}


.email-already {
    width: 340px;
    height: 18px;
    padding: 10px;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #E55454;
}


.invalid-email {
    width: 340px;
    height: 18px;
    padding: 10px;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #E55454;
}



@media (max-width:600px) {

    .container {
        /* padding-left: 10px; */
        max-width: 600px;
        width: 100%;
    }

}
@media(max-width:768px) {
    .signup-main-modal{
        max-width: 345px;
    }
}

@media(max-width:500px) {
    .social-signup .google-btn p {
        font-size: 1rem;
    }

    .container {
        width: 100%;
        height: 100%;
        /* padding: 20px; */
    }
    .signup-modal h3 {
        font-size: 18px;
    }
    .signup-modal p {
        font-size: 11px;
    }
    .register-form button {
        width: 70%
    }
}



.sign-in-title {
    font-family: 'Poppins', sans-serif;
    font-size: 27px;
    font-weight: 500;
    line-height: 40.5px;
    text-align: center;

}


/* Verified */


.continue-button-div{
    margin: 53px 0px 20px;
}
.continue-button{
    padding: 12px 24px 12px 24px;
    margin-top: 30px;
    text-decoration: none;
    border-radius: 27px;
    color: white;
    background-color: var(--background-dark-blue);
}
