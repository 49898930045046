/* Base styles for the login container */
.login-container {
    display: flex;
    width: 100vw;
    min-height: 100vh; /* Changed from height to min-height */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.left-side {
    flex: 1;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    padding-left: 60px;
    background: url('../../../public/images2/Backdrop.png') no-repeat center center;
    background-size: cover;
}

.welcome-text {
    color: white;
    text-align: left;
    display: flex;
    flex-direction: column;
    padding-top: 100px;
    gap: 10px;
}

.welcome-text .circle {
    background-color: white;
    padding: 20px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.welcome-text h1 {
    font-size: 56px;
    color: white;
    margin-bottom: 10px;
}

.welcome-text p {
    font-size: 24px;
}

.right-side {
    flex: 1;
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 0px 40px 0px 40px;
}

.login-box {
    width: 100%;
    max-width: 700px;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.signup-link {
    text-align: right;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.signup-link-left{
    text-align: left;
    /* margin-top: 20px; */
}

.signup-link a {
    color: #333;
    font-weight: bold;
}

.back-to-home {
    font-size: 22px;
    text-align: left;
    width: fit-content;
    padding: 4px;
}

.top-signup-link{
    margin: 0;
}

.signup-link-left a {
    color: #333;
    font-weight: bold;
}
.login-screen-logo{
    display: none;
}

.signin-heading {
    font-size: 34px;
    margin-top: 10px;
    text-align: left;
    font-weight: 500;
    color: #333333;
}

.social-login {
    display: flex;
    flex-direction: column;
    align-items: center;/* did this for login with google button  */
    margin: 10px 0;
}

.social-login button {
    margin: 10px 0;
    padding: 20px;
    height: 50px;
    border: 1px solid;
    border-radius: 27px;
    cursor: pointer;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    gap: 20px;
    border-color: #333333;
}

.google-btn {
    color: #333333;
    text-align: center;
}

.or {
    margin: 0px 0px 5px 0px;
    color: #999;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.or span {
    width: 300px;
    color: #999;
    border-bottom: 1px solid;
}

.signin-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.username {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
}

.username label {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #666666;
}


.sigin-inputs {
    padding: 20px;
    width: 95%;
    margin-bottom: 15px;
    border: 1px solid #66666659;
    border-radius: 5px;
}

.password-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 10px;
}

.password-container label {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #666666;
}

.pass {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #66666659;
    border-radius: 5px;
    width: 100%;
}

.password-container .toggle-password {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 10px;
    gap: 5px;
    top: 7%;
    cursor: pointer;
    color: #999;
}

.forgot-password {
    text-align: right;
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #111111;
    /* text-decoration: none; */
}

.forgot-password:hover {
    color: #333;
}

.signin-btn {
    background-color: #111111;
    color: white;
    padding: 20px;
    border: none;
    border-radius: 60px;
    cursor: pointer;
    font-size: 16px;
    opacity: 25%;
    width: 40%;
}

.signin-btn:hover {
    opacity: 100%;
}
.social-login-bottom {
    display: none;
}

.social-login-bottom button{
    margin: 10px 0;
    padding: 20px;
    height: 50px;
    border: 1px solid;
    border-radius: 27px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    gap: 20px;
    border-color: #333333;
}
.signup-link-bottom {
    margin-top: 20px;
    font-size: 14px;
    text-align: center;
}

.signup-link-bottom a {
    color: #333;
    font-weight: bold;
}

.signup-link-bottom a:hover {
    text-decoration: underline;
}

@media (max-width: 1024px) {
    .left-side {
        display: none;
    }
}

@media (max-width: 930px) {
    .signup-link{
        display: none;
    }
    .or{
        display: none;
    }
    .login-container {
        justify-content: center;
        align-items: center;
        background-image: url(/public/images2/Backdrop.png);
        padding: 0; /* Remove padding */
    }

    .right-side {
        background-color: white;
        width: 100%;
        max-width: 345px; /* Adjusted width for mobile */
        padding: 35px 35px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 24px; /* Rounded corners */
        box-sizing: border-box;
    }
    .login-box{
        align-items: center;
    }

    .signin-heading {
        font-size: 27px; /* Adjusted font size */
        margin-bottom: 20px;
        margin-top: 20px;
        font-family: poppins;
        font-weight: 500;
        color: #333333;
        text-align: center; /* Centered text */
    }

    .signin-form {
        /* width: 100%; */
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }
    .password-container .toggle-password {
        display: none;
    }

    .sigin-inputs {
        width: 100%;
        padding: 15px;
        margin-bottom: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 16px;
        box-sizing: border-box;
    }

    .signin-btn {
        background-color: var(--background-dark-blue); /* Blue background */
        color: white;
        padding: 13px;
        border: none;
        border-radius: 27px;
        cursor: pointer;
        font-size: 16px;
        opacity: 100;
    }

    .signin-btn:hover {
        background-color: #004494; /* Darker blue on hover */
    }
    .forgot-password {
        display: none;
    }
    .social-login-bottom{
        display: flex;
    }
    .social-login{
        display: none;
    }
    .login-screen-logo{
        display: block;
        width: 150px;
    }
    .username label{
        display: none;
    }
    .password-container label{
        display: none;
    }
    .signup-link-bottom {
        font-size: 14px;
        margin-top: 10px;
    }

    .signup-link-bottom a {
        color: #0056b3; /* Blue color */
        font-weight: bold;
        text-decoration: none;
    }

    .signup-link-bottom a:hover {
        text-decoration: underline;
    }
}

@media (max-width: 375px) {
    .right-side {
        padding: 15px;
        /* max-width: 310px; */
    }

    .signin-heading {
        font-size: 24px;
    }

    .signin-form {
        gap: 10px;
    }

    .sigin-inputs {
        padding: 10px;
    }

    .signin-btn {
        padding: 10px;
    }

    .social-login button {
        padding: 10px;
        height: 40px;
        font-size: 14px;
    }
}
